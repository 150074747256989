<template>
  <v-container>
    <!-- file upload -->
    <vue-dropzone
      id="dropzone"
      ref="myVueDropzone"
      :use-custom-slot="true"
      :options="dropzoneOptions"
      @vdropzone-complete="afterComplete"
    >
      <div class="dropzone-custom-content">
        <v-icon x-large> mdi-cloud-upload </v-icon>
        <h4>테스트 테마의 이미지를 등록</h4>
      </div>
    </vue-dropzone>
    <div class="text-center mt-4">
      <v-btn
        v-show="!hasImg"
        :disabled="!isUpload"
        type="button"
        class="btn btn-primary"
        color="yellow"
        @click="saveImg"
      >
        사진 접수
      </v-btn>
      <v-btn
        v-show="hasImg"
        type="button"
        class="btn btn-primary"
        disabled
      >
        저장 완료
      </v-btn>
      <v-card-text class="text-caption">
        먼저 사진을 접수하고 아래 내용을 입력해주세요.<br />
        사진은 1장만 저장됩니다.<br />
        * 권장 이미지: png, 정사각형
      </v-card-text>
    </div>
  </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { mapMutations } from 'vuex'
/**
 * Form-upload component
 */
export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzone: '',
      filedata: '',
      filename: '',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        maxFilesize: 5,
        addRemoveLinks: true,
        headers: {
          'My-Awesome-Header': 'header value',
        },
      },
      hasImg: false,
      isUpload: false,
    }
  },
  watch: {},
  methods: {
    ...mapMutations('common', ['setLoading']),
    afterComplete(file) {
      this.isUpload = true
      this.filedata = file
    },
    saveImg() {
      this.setLoading(true)

      const metaData = {
        contentType: 'image/png',
      }
      const storage = getStorage()
      this.filename = new Date().toLocaleString()

      const storageRef = ref(storage, `thumbnails/${this.filename}.png`)
      uploadBytes(storageRef, this.filedata, metaData).then(snapshot => {
        localStorage.setItem('thumb', `thumbnails/${this.filename}.png`)
        setTimeout(() => {
          this.hasImg = true
          this.setLoading(false)
        }, 1500)
      })
    },
  },
}
</script>
